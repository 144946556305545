import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  projectBags: [],
  projectBag: {},
  totalCountProjectBags: 0
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Project bags
  async createProjectBag(_, bagData) {
    await MicrowdAPI({ action: "createBag", data: bagData });
  },

  async getProjectBags({ commit }, data) {
    commit("SET_PROJECT_BAGS", []);

    try {
      const response = await elasticSearchApi.post("/bag", data);

      commit("SET_TOTAL_COUNT_PROJECT_BAGS", response.data.total);
      commit("SET_PROJECT_BAGS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getProjectBag({ commit }, data) {
    try {
      const response = await elasticSearchApi.post("/bag", data);
      commit("SET_PROJECT_BAG", response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  },

  async calculateCreditsTransfersInBag(_, projectBagId) {
    const response = await MicrowdAPI({ action: "calculateCreditsTransfersInBag", data: projectBagId });
    return response.data.data;
  },

  // Change project bag status to investable
  async changeToInvestable(_, projectBagId) {
    await MicrowdAPI({ action: "changeBagToInvestable", data: projectBagId });
  },

  // Edit project bag data
  async editProjectBag(_, data) {
    await MicrowdAPI({ action: "editBag", data: data });
  },

  async addProjectsBag(_, data) {
    await MicrowdAPI({ action: "addProjectsBag", data });
  },

  async investProjectBag(_, data) {
    await MicrowdAPI({ action: "investBag", data });
  },

  async createMoneyTransfersInBag(_, data) {
    await MicrowdAPI({ action: "createMoneyTransferInBag", data });
  },

  // Delete a project of the project bag
  async deleteProjectOfProjectBag(_, data) {
    await MicrowdAPI({ action: "removeProjectBag", data });
  },

  // Delete a project bag
  async deleteProjectBag(_, projectBagId) {
    await MicrowdAPI({ action: "removeBag", data: projectBagId });
  },

  // Delete investment
  async deleteInvestment(_, data) {
    await MicrowdAPI({ action: "removeInvestmentBag", data });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_PROJECT_BAGS(state, projectBags) {
    state.projectBags = projectBags;
  },

  SET_PROJECT_BAG(state, projectBag) {
    state.projectBag = projectBag;
  },

  SET_TOTAL_COUNT_PROJECT_BAGS(state, totalCount) {
    state.totalCountProjectBags = totalCount;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
